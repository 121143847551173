import axios from 'axios';
import * as Crypto from './crypto';
import * as Utils from './utils';
import Storage from './react/storage';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as Debug from './debug';

export type TRequestConfig = AxiosRequestConfig & {
    parseToken?: boolean;
    saveToken?: boolean;
};

export type TRequestResponse = AxiosResponse & {
    isError: boolean;
};

export type TCredentialConfig = {
    url?: string;
    parse_token?: boolean;    
    token_name?: string;
    bearer_token?: boolean;
    aes_secret?: string;
    aes_iv?: string;
    attach_token_to_param?: boolean;
    default_api_url?: boolean;
    attach_token_to_header?: boolean;
    error_log?: boolean;
};

export type TParams = FormData | object | undefined | null | string;

export type TMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

export type TRequestData = {
    url: string;
    params: TParams;
    config: TRequestConfig;
    credentialConfig: TCredentialConfig;
    method: TMethod;
    bodyTypeParam?: boolean;
    errorLog?: boolean;
};

export type TResponseListener = (response: TRequestResponse, requestData?: TRequestData) => Promise<TRequestResponse | void | string>;

const _tokens = {};

let _responseListenerIndex = 1;

export const getToken = async (name: string) =>
{
    if(name in _tokens == false)
    {
        _tokens[name] = await Storage.getItem(name);
    }

    return _tokens[name];
};

export const setToken = async (name: string, token: string, saveStorage: boolean = true) =>
{
    _tokens[name] = token;

    if(saveStorage) Storage.setItem(name, token);
};

class Request
{
    static #_defaultApiUrl: string = "";
    
    static #_credentialConfigs: Array<TCredentialConfig> = [{
        url: "http://localhost:4000",
        token_name: "access_token",
        parse_token: true,
        bearer_token: false,
        aes_secret: "",
        aes_iv: "",
        attach_token_to_param: false,
        default_api_url: true,
        error_log: true
    }];

    static #_responseListeners: { [index: string]: TResponseListener } = {};

    static addResponseListener(listener: TResponseListener)
    {
        const id = 'ID' + (++_responseListenerIndex);

        this.#_responseListeners[id] = listener;

        return id;
    }

    static removeResponseListener(id: string)
    {
        delete this.#_responseListeners[id];        
    }
    
    static getCredentialConfig(index: number = 0) { return index < this.#_credentialConfigs.length ? this.#_credentialConfigs[index] : this.#_credentialConfigs[0]; }

    static setCredentialConfig(configs: Array<TCredentialConfig>)
    {
        this.#_credentialConfigs = configs;

        for(let itemCur of configs)
        {
            if(!this.#_defaultApiUrl || itemCur.default_api_url === true)
            {
                this.#_defaultApiUrl = itemCur.url + (itemCur.url.charAt(itemCur.url.length - 1) == '/' ? '' : '/');

                break;
            }
        }
    };

    static #_applyCredentialConfigs = async (method: TMethod, url: string, params?: TParams, config?: TRequestConfig, bodyTypeParam: boolean = true, errorLog: boolean = true) => 
    {
        let credentialConfig: TCredentialConfig = null;

        if(url.indexOf('preset[') == 0)
        {
            const presetIndex: number = parseInt(url.substring(7, url.indexOf(']')));

            if(presetIndex < this.#_credentialConfigs.length)
            {
                credentialConfig = this.#_credentialConfigs[presetIndex];

                url = credentialConfig.url + url.substring(url.indexOf(']') + 1);
            }
        }

        if(url.indexOf('://') < 0 && this.#_defaultApiUrl)
        {
            url = Utils.joinPath(this.#_defaultApiUrl, url);
        }

        if(!credentialConfig)
        {
            credentialConfig = this.#_credentialConfigs.find((obj) => { return url.indexOf(obj.url) === 0 ? true : false; });
        }

        if(credentialConfig)
        {
            if(credentialConfig.token_name)
            {
                let token = await getToken(credentialConfig.token_name);

                if(token)
                {
                    const tokenName = credentialConfig.bearer_token ? 'Authorization' : credentialConfig.token_name;

                    if(credentialConfig.bearer_token)
                    {
                        token = 'Bearer ' + token;
                    }
    
                    if(credentialConfig.attach_token_to_param)
                    {
                        if(!params) params = {};
        
                        if(typeof params == 'object')
                        {
                            if(params?.constructor?.name == 'FormData')
                            {
                                (params as FormData).append(tokenName, token);
                            }
    
                            else
                            {
                                params[tokenName] = token;
                            }
                        }    
                    }
        
                    if(!config) config = {};
        
                    if(typeof config == 'object')
                    {
                        config['withCredentials'] = true;
        
                        if(token && credentialConfig.attach_token_to_header)
                        {
                            if(!config.headers) config.headers = {};
        
                            const tokenNameCur = credentialConfig.bearer_token ? tokenName : Utils.replaceAll(tokenName, '_', '-');
    
                            if(tokenNameCur in config.headers == false)
                            {
                                config.headers[tokenNameCur] = token;    
                            }
                        }
                    }        
                }
            }

            if(credentialConfig.aes_secret && credentialConfig.aes_iv)
            {
                if(typeof params == 'object' && params?.constructor?.name == 'Object')
                {
                    for(let keyCur in params)
                    {
                        params[keyCur] = Crypto.SHAAES.encrypt(params[keyCur], credentialConfig.aes_secret, credentialConfig.aes_iv);
                    }
                }

                else if(typeof params == 'string')
                {
                    params = Crypto.SHAAES.encrypt(params, credentialConfig.aes_secret, credentialConfig.aes_iv);
                }
            }
        }

        if(!bodyTypeParam && typeof params == 'object')
        {
            let queryStr = "";

            for(let keyCur in params)
            {
                queryStr += ((queryStr.length > 0 ? "&" : "") + keyCur + "=" + params
                [keyCur]);
            }

            url += (url.indexOf('?') > 0 ? "&" : "?") + queryStr;
        }

        return {
            url,
            params,
            config,
            credentialConfig,
            method,
            bodyTypeParam,
            errorLog
        };
    };

    static #_checkResult = async (response: TRequestResponse, requestData: TRequestData, ignoreListener: boolean = false): Promise<TRequestResponse> =>
    {
        const credentialConfig: TCredentialConfig = requestData.credentialConfig;
        const requestConfig: TRequestConfig = requestData.config;

        if(response?.data)
        {
            if(requestConfig?.parseToken != false && credentialConfig?.parse_token != false && credentialConfig?.token_name && typeof response.data == 'object' && "tokens" in response.data && credentialConfig?.token_name in response.data.tokens)
            {
                const saveToken = requestConfig?.saveToken == false ? false : true;

                await setToken(credentialConfig.token_name, response.data.tokens[credentialConfig?.token_name], saveToken);

                delete response.data.token;
            }
        }

        if(response?.isError && requestData?.errorLog && credentialConfig?.error_log)
        {
            Debug.error({
                url: requestData?.url,
                method: requestData?.method,
                params: requestData?.params,
                config: requestData?.config,
                response: response?.data
            });            
        }

        if(!ignoreListener && Object.keys(this.#_responseListeners).length > 0)
        {
            let finalResponse = null;

            for(const keyCur in this.#_responseListeners)
            {
                const listenerCur : TResponseListener = this.#_responseListeners[keyCur];
                
                if(listenerCur) 
                {
                    let responseCur = await listenerCur(response, requestData);

                    if(responseCur && !finalResponse) finalResponse = responseCur;
                }
            }

            if(finalResponse) return finalResponse;
        }

        return response;
    };

    static async request(requestData: TRequestData, ignoreListener: boolean = false): Promise<TRequestResponse>
    {
        let response: TRequestResponse = null;

        switch(requestData.method)
        {
            case 'get':
            case 'delete':
                {
                    try
                    {
                        const axiosResponse = await axios.request({
                            method: requestData.method,
                            url: requestData.url,
                            ...requestData.config
                        });
            
                        response = { ...axiosResponse, isError: false };
                    }
                    catch(e)
                    {
                        response = { ...e?.response, isError: true };
                    }            
                    break;
                }
            default:
                {
                    try
                    {
                        const axiosResponse = await axios.request({
                            method: requestData.method,
                            url: requestData.url,
                            data: requestData.params,
                            ...requestData.config
                        });
                        
                        response = { ...axiosResponse, isError: false };
                    }
                    catch(e)
                    {
                        response = { ...e?.response, isError: true };
                    }            
                    break;
                }
        }

        return await this.#_checkResult(response, requestData, ignoreListener);
    }

    static async patch(url: string, params?: TParams, config: TRequestConfig = null, errorLog: boolean = true): Promise<TRequestResponse>
    {
        const requestData: TRequestData = await this.#_applyCredentialConfigs('patch', url, params, config, true, errorLog);

        return await this.request(requestData);
    };

    static async put(url: string, params: TParams, config: TRequestConfig = null, errorLog: boolean = true): Promise<TRequestResponse>
    {
        const requestData: TRequestData = await this.#_applyCredentialConfigs('put', url, params, config, true, errorLog);

        return await this.request(requestData);
    };

    static async post(url: string, params?: TParams, config: TRequestConfig = null, errorLog: boolean = true): Promise<TRequestResponse>
    {
        const requestData: TRequestData = await this.#_applyCredentialConfigs('post', url, params, config, true, errorLog);

        return await this.request(requestData);
    };

    static async postFile(url: string, params?: TParams, config: TRequestConfig = { headers: { "Content-Type": 'multipart/form-data' }}, errorLog: boolean = true): Promise<TRequestResponse>
    {
        return await this.post(url, params, config, errorLog);
    }

    static async get(url: string, params?: TParams, config: TRequestConfig = null, errorLog: boolean = true): Promise<TRequestResponse>
    {
        const requestData: TRequestData = await this.#_applyCredentialConfigs('get', url, params, config, false, errorLog);

        return await this.request(requestData);
    };

    static async delete(url: string, params?: TParams, config: TRequestConfig = null, errorLog: boolean = true): Promise<TRequestResponse>
    {
        const requestData: TRequestData = await this.#_applyCredentialConfigs('delete', url, params, config, false, errorLog);

        return await this.request(requestData);
    };
};

export default Request;