import * as Config from '../config';
import * as Crypto from '../crypto';
import { Cookies as ReactCookies, useCookies } from 'react-cookie';
import moment from 'moment';

export interface CookieGetOptions {
    doNotParse?: boolean;
    doNotUpdate?: boolean;
}
export interface CookieSetOptions {
    path?: string;
    expires?: Date;
    maxAge?: number;
    domain?: string;
    secure?: boolean;
    httpOnly?: boolean;
    sameSite?: boolean | 'none' | 'lax' | 'strict';
}
export interface CookieChangeOptions {
    name: string;
    value?: any;
    options?: CookieSetOptions;
}

export { useCookies };

export default class Cookies
{
    static #_AES_SECRET: string = Config.CRYPTO.COOKIE_SECRET;

    static #_cookies = new ReactCookies();

    static setSecret(secret: string)
    {
        this.#_AES_SECRET = secret;
    }

    static get(name: string, defaultValue: any = null)
    {
        return this.load(name, defaultValue);
    }

    static load(name: string, defaultValue: any = null)
    {
        let cookieCur = defaultValue;
        
        let val = this.#_cookies.get(name);

        if(val == null) return null;

        if(val.length > 0)
        {
            try
            {
                let str = this.#_AES_SECRET ? Crypto.AES.decrypt(val, this.#_AES_SECRET) : val;

                cookieCur = JSON.parse(str);
            }
            catch(e)
            {
                cookieCur = val;
            }
        }        

        return cookieCur;
    }

    static set(name: string, data: string | object, expireSec: number = 0, path: string = null, domain: string = null, extraOptions: CookieSetOptions = null)
    {
        return this.save(name, data, expireSec, path, domain, extraOptions);
    }

    static save(name: string, data: string | object, expireSec: number = 0, path: string = null, domain: string = null, extraOptions: CookieSetOptions = null)
    {
        const options: CookieSetOptions = {};

        if(path) options.path = path;
        
        if(domain) options.domain = domain;         

        if(extraOptions && typeof extraOptions == 'object')
        {
            for(let keyCur in extraOptions)
            {
                options[keyCur] = extraOptions[keyCur];
            }
        }

        if(data == null)
        {
            this.delete(name, options);
        }

        else
        {
            if(expireSec)
            {
                let dateExpired = new Date();
    
                dateExpired.setSeconds(moment().toDate().getSeconds() + expireSec);
    
                options.expires = dateExpired;
            }
            
            let str = JSON.stringify(data);

            this.#_cookies.set(name, this.#_AES_SECRET ? Crypto.AES.encrypt(str, this.#_AES_SECRET) : str, options);
        }
    }

    static delete(name: string, options: CookieSetOptions = {})
    {
        return this.remove(name, options);
    }

    static remove(name: string, options: CookieSetOptions = {})
    {
        if(!options) options = {};

        this.#_cookies.remove(name, { ...options, maxAge: 0 });
    }

    static encrypt(data: string)
    {
        let str = JSON.stringify(data);

        return this.#_AES_SECRET ? Crypto.AES.encrypt(str, this.#_AES_SECRET) : str;
    }

    static decrypt(data: string)
    {
        let cookieCur = data && this.#_AES_SECRET ? Crypto.AES.decrypt(data, this.#_AES_SECRET) : data;

        return cookieCur ? JSON.parse(cookieCur) : null;
    }
}